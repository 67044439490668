import { Outlet } from "react-router-dom";
import {
    Row,
    Col } from "react-bootstrap";

import NavSide from "./components/NavSide";

export default function NewDocOutlet(){
  /* Define Items in the Sidebar */
  const sideNav = [
    {
      title: "Document Information",
      page: "info"
    },
    {
      title: "Templates",
      page: "temps",
    },
    {
      title: "Clone",
      page: "clone",
    },
    {
      title: "Users",
      page: "users"
    }
  ]
  return(
    <>
      <Row className="page-head">
        <h1>
          Create a New Document
        </h1>
      </Row>
      <Row>
        <Col sm="2" className="mx-0 px-0">
          <NavSide elements={sideNav} finalBtn="Create" type="docs"/>
        </Col>
        <Col className="px-5">
          <Outlet />
        </Col>
      </Row>
    </>
  );
}