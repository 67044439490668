import { Row } from "react-bootstrap";

import ElementList from "./components/ElementList";
  
export default function TempsList() { 
  // Sample elements list
  const sampleList = [
    {
      title: "Sample Protocol Name",
      description: "based on elemental, version 3.4",
      id: "01293",
    },
    {
      title: "Another Name of a Protocol Hybrid",
      description: "shorter here, draft",
      id: "0129rr3",
    },
    {
      title: "Sample Protocol Name",
      description: "based on elemental, version 3.4",
      id: "0129tt3",
    },
    {
      title: "Another Name of a Protocol Hybrid",
      description: "shorter here, draft",
      id: "0ss1293",
    },
    {
      title: "Sample Protocol Name",
      description: "based on elemental, version 3.4",
      id: "01293sdf",
    },
    {
      title: "Another Name of a Protocol Hybrid",
      description: "shorter here, draft",
      id: "0129sdfds3",
    }
  ]
  
  return(
    <>
      <Row className="page-head">
        <h1>
          Templates
        </h1>
      </Row>
      <Row>
        <ElementList variant="draft" page="template" type="protocol" listItems={sampleList}/>
      </Row>
      <Row>
        <ElementList variant="live" page="template" type="protocol" listItems={sampleList} />
      </Row>
    </>
  );
}