import AscButton from "./AscButton";
import AscButtonGroup from "./AscButtonGroup";
import TinyEditor from "./TinyEditor";
import TinyPreview from "./TinyPreview";

import { Container } from 'react-bootstrap';

function OpenSection(props) {
  return(
    <div>
      <div className="tinyedit-grp">
        <AscButton variant='tinyedit-head'>{props.children}</AscButton>
        <AscButtonGroup variant='cancel-save' />
      </div>
      <TinyEditor text={props.text} />
    </div>
  );
}

function ClosedSection(props) { 
  return(
    <div>
      <div className="tinyedit-grp">
        <AscButton variant="tinyedit-head">{props.children}</AscButton>
      </div>
      <TinyPreview text={props.text} variant={props.type} />
    </div>
  );
}

function BlockedSection(props){
  return( 
    <div>
      <div className="tinyedit-grp">
        <AscButton variant="tinyedit-head" startoff="{true}">{props.children}</AscButton>
      </div>
      <TinyPreview text={props.text} variant={props.type} />
    </div>
  );
}

function EditSection(props) {

  return (
    <Container className="pe-5">
      <h3>
        {props.title}
        <AscButtonGroup variant="edit-delete" />
      </h3>
      {
        (props.editorSection==="") ? 
          <>
            <ClosedSection text={props.textGuidance} type="guidance">Guidance Text</ClosedSection>
            <ClosedSection text={props.textMain} type="mainpreview">Default Text</ClosedSection>
          </> 
        : (props.editorSection===props.title) ? 
          <>
            {
              (props.editorPart==="guidance") ?
                <> 
                  <OpenSection text={props.textGuidance} type="guidance">Guidance Text</OpenSection>
                  <BlockedSection text={props.textMain} type="mainpreview">Default Text</BlockedSection>
                </>
              : 
                <> 
                  <BlockedSection text={props.textGuidance} type="guidance">Guidance Text</BlockedSection>
                  <OpenSection text={props.textMain} type="mainpreview">Default Text</OpenSection>
                </>
            }
          </> 
        : 
          <>
            <BlockedSection text={props.textGuidance} type="guidance">Guidance Text</BlockedSection>
            <BlockedSection text={props.textMain} type="mainpreview">Default Text</BlockedSection>
          </> 
      }
    </Container>
  );
}

export default EditSection;