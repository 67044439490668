import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";

import NavTop from "./components/NavTop";

export default function MainOutlet(){
  return(
    <Container fluid>
      <NavTop />
      <Container fluid className="p-5 me-0 ms-3">
        <Outlet />
      </Container>
    </Container>
  );
}