import ElementList from "./components/ElementList";
  
export default function SelectorDocs(props) { 
  // Sample elements list
  const sampleList = [
    {
      title: "Sample Protocol Name",
      description: "based on elemental, version 3.4",
      id: "01293",
    },
    {
      title: "Another Name of a Protocol Hybrid",
      description: "shorter here, draft",
      id: "0129rr3",
    },
    {
      title: "Sample Protocol Name",
      description: "based on elemental, version 3.4",
      id: "0129tt3",
    },
    {
      title: "Another Name of a Protocol Hybrid",
      description: "shorter here, draft",
      id: "0ss1293",
    },
    {
      title: "Sample Protocol Name",
      description: "based on elemental, version 3.4",
      id: "01293sdf",
    },
    {
      title: "Another Name of a Protocol Hybrid",
      description: "shorter here, draft",
      id: "0129sdfds3",
    }
  ]

  const sampleList2 = [
    {
      title: "Sample List 2 Protocol Name",
      documents: "Protocol, SAP",
      id: "0000a",
    },
    {
      title: "Another Name of a Protocol Hybrid",
      documents: "Protocol, SAP",
      id: "00001",
    },
    {
      title: "Sample Protocol Name",
      documents: "Protocol",
      id: "00002",
    },
    {
      title: "Another Name of a Protocol Hybrid",
      documents: "Protocol, SAP",
      id: "00003",
    },
    {
      title: "Sample Protocol Name",
      documents: "SAP",
      id: "00004",
    },
    {
      title: "Another Name of a Protocol Hybrid",
      documents: "Protocol, SAP",
      id: "00005",
    }
  ]
  var useList = [];
  (props.page==="proj-clone") ? useList = sampleList2 : useList = sampleList;
  return(
    <ElementList variant="live" page={props.page} type={props.type} listItems={useList} />
  );
}