import { Outlet } from "react-router-dom";
import {
    Row,
    Col } from "react-bootstrap";

import NavSide from "./components/NavSide";

export default function DocsOutlet(){
  /* Define Items in the Sidebar */
  const sideNav = [
    {
      title: "Data Form",
      page: "var"
    },
      {
        title: "Study Info",
        page: "var/study",
        sub: "level2",
      },
      {
        title: "Population",
        page: "var/pop",
        sub: "level2",
      },
    {
      title: "Main Text",
      page: "txt",
    },
    {
      title: "Synopsis",
      page: "syn",
    },
    {
      title: "Standard Pages",
      page: "std",
    },
    {
      title: "Manage Variables",
      page: "manage-vars"
    },
    {
      title: "Export",
      page: "export"
    }
  ]
  return(
    <>
      <Row className="page-head">
        <h1>
          #DocumentTitle or New Document
        </h1>
      </Row>
      <Row>
        <Col sm="2" className="mx-0 px-0">
          <NavSide elements={sideNav} type="docs" />
        </Col>
        <Col className="px-5">
          <Outlet />
        </Col>
      </Row>
    </>
  );
}