import { Row } from "react-bootstrap";

import ElementList from "./components/ElementList";
  
export default function DocsList() { 
  // Sample elements list
  const sampleList = [
    {
      title: "Sample Protocol Name",
      description: "based on elemental, version 3.4",
      id: "0000a",
    },
    {
      title: "Another Name of a Protocol Hybrid",
      description: "shorter here, draft",
      id: "00001",
    },
    {
      title: "Sample Protocol Name",
      description: "based on elemental, version 3.4",
      id: "00002",
    },
    {
      title: "Another Name of a Protocol Hybrid",
      description: "shorter here, draft",
      id: "00003",
    },
    {
      title: "Sample Protocol Name",
      description: "based on elemental, version 3.4",
      id: "00004",
    },
    {
      title: "Another Name of a Protocol Hybrid",
      description: "shorter here, draft",
      id: "00005",
    }
  ]
  
  return(
    <>
      <Row className="page-head">
        <h1>
          Documents
        </h1>
      </Row>
      <Row>
        <ElementList variant="live" page="document" type="protocol" listItems={sampleList} />
      </Row>
    </>
  );
}