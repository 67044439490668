import { Row } from "react-bootstrap";

import ElementList from "./components/ElementList";
  
export default function DocsList() { 
  // Sample elements list
  const sampleList = [
    {
      title: "Sample Protocol Name",
      documents: "Protocol, SAP",
      id: "0000a",
    },
    {
      title: "Another Name of a Protocol Hybrid",
      documents: "Protocol, SAP",
      id: "00001",
    },
    {
      title: "Sample Protocol Name",
      documents: "Protocol",
      id: "00002",
    },
    {
      title: "Another Name of a Protocol Hybrid",
      documents: "Protocol, SAP",
      id: "00003",
    },
    {
      title: "Sample Protocol Name",
      documents: "SAP",
      id: "00004",
    },
    {
      title: "Another Name of a Protocol Hybrid",
      documents: "Protocol, SAP",
      id: "00005",
    }
  ]
  
  return(
    <>
      <Row className="page-head">
        <h1>
          Projects
        </h1>
      </Row>
      <Row>
        <ElementList variant="live" page="project" type="project" listItems={sampleList} />
      </Row>
    </>
  );
}