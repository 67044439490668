import { 
  Navbar, 
  Nav, 
  NavDropdown, 
  Container } from 'react-bootstrap';
import { NavLink
 } from 'react-router-dom';
import LogoIpsum from "./LogoIpsum";
import Plus from "./Icons";
import { Profile } from "./Icons";

const bgrd={
  margin: '0',
  backgroundColor: 'var(--secondary-bgrd-color)',
}

function NavTop() {
  return(
    <Navbar style={bgrd} className="p-4">
      <Container fluid>
        <LogoIpsum padding='pt-2' variant='kinetika' />
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav className="justify-content-end">
            <NavLink className="main nav-link" to="/app/components">UI-Reference</NavLink>
            <NavLink className="main nav-link" to="/app/dash">Dashboard</NavLink>
            <NavLink className="main nav-link" to="/app/temps">Templates</NavLink>
            <NavLink className="main nav-link" to="/app/docs">Documents</NavLink>
            <NavLink className="main nav-link" to="/app/projs">Projects</NavLink>
            <NavDropdown title="Create New" id="navNewItem">
              <NavDropdown.Item href="/app/temps/new">
                <Plus />Template
              </NavDropdown.Item>
              <NavDropdown.Item href="/app/docs/new">
                <Plus />Document
              </NavDropdown.Item>
              <NavDropdown.Item href="/app/projs/new">
                <Plus />Project
              </NavDropdown.Item>
            </NavDropdown>
            <Navbar.Brand href="/app/profile">
              <Profile/>
            </Navbar.Brand>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavTop;