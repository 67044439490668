import { 
  Container, 
  Row, 
  Col } from "react-bootstrap";

import Plus from "./Icons";
import AscButtonGroup from "./AscButtonGroup";

function ListHeader(props) {
  if (props.cols){
    const columns = Object.keys(props.cols);
    return columns.map((d) => 
    {
      return <Col md={props.colWidth} className="mb-1 px-0" key={"doc-list-head-"+d}>
        <p className="list-head">
          {props.cols[d].name}
        </p>
      </Col>;
    })
  }
  else{
    return "";
  }
}

function ListItem(props) {

  if(props.items){
    const items = Object.keys(props.items);
    const columns = Object.keys(props.cols);
    
    return items.map((d) =>
    {
      const element = props.items[d];

      return <Row className="list-row" key={"doc-list-item-" + d}>
        {
          columns.map((e) =>
          {
            return <Col md={props.colWidth} className="px-0" key={"doc-list-item-"+d+e}>
                <p className="list-txt">{element[props.cols[e].id]}</p>
              </Col>;
          })
        }
        <Col>
          <AscButtonGroup variant={props.btns} item={element} type="list" />
        </Col>
      </Row>;
    });
  }
  else{
    return "";
  }
}

function ListTitle(props){
  var title ="";
  if(props.variant === "draft") {
    title="Drafts";
  }
  else {
    title=props.type + "s";
  }
  return(
    <h2><span className="center">{title}</span> &nbsp; &nbsp;<Plus /></h2>
  );
}

export default function ElementList(props) {  
  // default table headers for Templates
  var columnNames = [];
  switch(props.page){
    case "project":
    case "proj-clone":
      (
        columnNames= [
          {
            name: "Title",
            id: "title"       
          },
          {
            name: "Documents",
            id: "documents" 
          },
        ]
      );
      break;
    case "template":
    case "document": 
    case "doc-creator":
    case "temp-creator":
    case "doc-clone":
    default:
      (
        columnNames= [
          {
            name: "Title",
            id: "title"       
          },
          {
            name: "Description",
            id: "description" 
          },
        ]
      );
  }

  // Define what type of button should appear on right end of list items
  var btnType="edit-delete";
  switch(props.page){
    case "proj-clone":
    case "doc-creator":
    case "temp-creator":
    case "doc-clone":
      (btnType="select");
      break;
    case "project":
    case "template":
    case "document": 
    default:
      (btnType="edit-delete");
  }

  // Determine whether or not a title should appear on the page, with an "add xxx" plus button option
  var titleNoTitle="yes";
  switch(props.page){
    case "proj-clone":
    case "doc-creator":
    case "temp-creator":
    case "doc-clone":
      (titleNoTitle="no");
      break;
    case "project":
    case "template":
    case "document": 
    default:
      (titleNoTitle="yes");
  }

  // Determine how many columns evenly distributes the columns except for the final "edit" column 
  // which gets 1-2 columns by default (or just the remainder space)
  var evenCols = 8;   // Default as defined for 1 column
  if( evenCols>1){
    evenCols = Math.floor(10/columnNames.length);
  }

  return(
    <Container>
      {
        (titleNoTitle==="yes") ? 
          <ListTitle variant={props.variant} type={props.type} />
        : <></>
      }
      <div className={"list-box " + props.variant}>
        <Row className="mb-0 pb-0">
          <ListHeader variant={props.variant} page={props.page} cols={columnNames} colWidth={evenCols}/>
        </Row>
        <ListItem colWidth={evenCols} cols={columnNames} items={props.listItems} btns={btnType}/>
      </div>
    </Container>
  );
}