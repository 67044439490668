import { 
  Link, 
  Outlet } from "react-router-dom";
import { Row } from "react-bootstrap";

export default function NewProjTemplates(){
  return(
    <div>
      New Document Templates landing page coming soon!<br/>
      This could be where you select if you're basing your document on an existing Protocol or SAP template;<br/>
      Alternatively, you could pick what kind of document your template is for on the Information page,<br/>
      And this page would autopopulate the your template selections (e.g. on Information, you set the document to be a Protocol, then this page loads with Protocol Templates). 
      <br/>
      <Link to="proto">Protocols</Link>&nbsp; &nbsp; &nbsp; &nbsp;<Link to="sap">SAPs</Link>
      <Row className="p-5">
        <Outlet />
      </Row>
    </div>
  );
}