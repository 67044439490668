function TinyPreview(props){
  return(
    <div className="tinybox">
      <p className={props.variant}>
        {props.text}
      </p> 
    </div>
  );
}

export default TinyPreview;