import { Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";

export default function NewProjClone(){
  return(
    <div>
      New Project Cloning landing page coming soon!<br/>
      From here you could select an existing project to clone.<br/>
      <Row className="p-5">
        <Outlet />
      </Row>
    </div>
  );
}