import { 
  Link, 
  Outlet } from "react-router-dom";
import { Row } from "react-bootstrap";

export default function NewProjTemplates(){
  return(
    <div>
      New Template Templates landing page coming soon!<br/>
      Here you will be able to select whether to start from scratch,<br/>
      or base your template on an existing template (*nb* I imagine this is not for versioning.<br/>
      to version, you would select "edit" from the template list.)<br/>
      This could be where you determine if you're basing your template on an existing Protocol or SAP template;<br/>
      Alternatively, you could pick what kind of document your template is for on the Information page,<br/>
      And this page would autopopulate the existing templates based on that selection. 
      <br/>
      <Link to="proto">Protocols</Link>&nbsp; &nbsp; &nbsp; &nbsp;<Link to="sap">SAPs</Link>
      <Row className="p-5">
        <Outlet />
      </Row>
    </div>
  );
}