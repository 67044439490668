import { Outlet } from "react-router-dom";
import {
    Row,
    Col } from "react-bootstrap";

import NavSide from "./components/NavSide";

export default function TempsOutlet(){
  
  /* Define Items in the Sidebar */
  const sideNav = [
    {
      title: "Outline",
      page: "outline"
    },
    {
      title: "Document Text",
      page: "txt"
    },
    {
      title: "Standard Pages",
      page: "std"
    },
    {
      title: "Synopsis",
      page: "syn"
    },
    {
      title: "Manage Variables",
      page: "manage-vars"
    }
  ]
  return(
    <>
      <Row className="page-head">
        <h1>
          #TemplateName or New Template
        </h1>
      </Row>
      <Row>
        <Col sm="2" className="mx-0 px-0">
          <NavSide elements={sideNav} type="temps"/>
        </Col>
        <Col className="px-5">
          <Outlet />
        </Col>
      </Row>
    </>
  );
}