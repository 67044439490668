import { 
  Link, 
  Outlet } from "react-router-dom";
import { Row } from "react-bootstrap";

export default function NewDocClone(){
  return(
    <div>
      New Document Cloning landing page coming soon!<br/>
      From here you could select an existing template to clone.<br/>
      This could autopopulate based on a document type selected in the Study Information page.<br/>
      Alternatively, you could browse different types of documents to clone here. 
      <br/>
      <Link to="proto">Protocols</Link>&nbsp; &nbsp; &nbsp; &nbsp;<Link to="sap">SAPs</Link>
      <Row className="p-5">
        <Outlet />
      </Row>
    </div>
  );
}