import React from 'react';
import ReactDOM from 'react-dom/client';
import{
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import './index.css';
import "../src/style.scss";

import RoutesRoot from './RoutesRoot';
import ErrorPage from "./ErrorPage";
import Login from "./Login";
import MainOutlet from "./MainOutlet";
import DocsList from "./DocsList";
import TempsList from "./TempsList";
import Dashboard from "./Dashboard";
import ComponentTest from "./ComponentTest";
import Profile from "./Profile";
import DocsOutlet from "./DocsOutlet";
import TempsOutlet from "./TempsOutlet";
import DataFormOutlet from "./DataFormOutlet";
import DataForm from "./DataForm";
import ProjsProtocol from "./ProjsProtocol";
import ProjsProtocolSynopsis from './ProjsProtocolSynopsis';
import ProjsProtocolStandardPages from "./ProjsProtocolStandardPages";
import ProjsProtocolText from "./ProjsProtocolText";
import ProjsSAP from "./ProjsSAP";
import ProjsSAPText from './ProjsSAPText';
import ProjsSAPStandardPages from "./ProjsSAPStandardPages";
import DataFormLanding from "./DataFormLanding";
import ProjsProtocolLanding from './ProjsProtocolLanding';
import ProjsSAPLanding from './ProjsSAPLanding';
import ManageVariables from "./ManageVariables";
import TempsOutline from './TempsOutline';
import TempsText from './TempsText';
import TempsStandardPages from "./TempsStandardPages";
import TempsSynopsis from './TempsSynopsis';
import NewProjOutlet from './NewProjOutlet';
import NewProjTemplates from './NewProjTemplates';
import NewProjUsers from './NewProjUsers';
import NewProjInfo from './NewProjInfo';
import TempsSave from './TempsSave';
import DocsSave from './DocsSave';
import ProjsList from './ProjsList';
import ProjsSave from './ProjsSave';
import ProjsOutlet from './ProjsOutlet';
import ProjsLanding from './ProjsLanding';
import DocsSynopsis from './DocsSynopsis';
import DocsStandardPages from './DocsStandardPages';
import DocsText from './DocsText';
import DocsExport from './DocsExport';
import ProjsExport from './ProjsExport';
import NewTempInfo from './NewTempInfo';
import NewDocInfo from './NewDocInfo';
import NewDocUsers from './NewDocUsers';
import NewDocClone from './NewDocClone';
import NewDocOutlet from './NewDocOutlet';
import NewTempOutlet from './NewTempOutlet';
import NewDocTemplates from './NewDocTemplates';
import NewTempTemplates from './NewTempTemplates';
import SelectorList from './SelectorList';
import NewProjClone from './NewProjClone';
import NewTempUsers from './NewTempUsers';

const router = createBrowserRouter([
  {
    path: "/",
    element: <RoutesRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Login />,
      },
      {
        path: "login",
        element: <Login />
      },
      {
        path: "app",
        element: <MainOutlet />,
        children: [
          {
            path: "",
            element: <Dashboard />,
          },
          {
            path: "components",
            element: <ComponentTest />,
          },
          {
            path: "dash",
            element: <Dashboard />,
          },
          {
            path: "docs",
            element: <DocsList />,
          },
          {
            path: "temps",
            element: <TempsList />,
          },
          {
            path: "projs",
            element: <ProjsList />,
          },
          {
            path: "projs/edit/:id",
            element: <ProjsOutlet />,
            children: [
              {
                path: "",
                element: <ProjsLanding />, 
              },
              {
                path: "var",
                element: <DataFormOutlet />,
                children: [
                  {
                    path: "",
                    element: <DataFormLanding />,
                  },
                  { 
                    path: "pop",
                    element: <DataForm page="Population" />,
                  },
                  {
                    path: "study",
                    element: <DataForm page="Study Information" />
                  }
                ]
              },
              {
                path: "proto",
                element: <ProjsProtocol />,
                children: [
                  {
                    path: "",
                    element: <ProjsProtocolLanding />
                  },
                  {
                    path: "syn",
                    element: <ProjsProtocolSynopsis />
                  },
                  {
                    path: "std",
                    element: <ProjsProtocolStandardPages />
                  },
                  {
                    path: "txt",
                    element: <ProjsProtocolText />
                  },
                ],
              },
              {
                path: "sap",
                element: <ProjsSAP />,
                children: [
                  {
                    path: "",
                    element: <ProjsSAPLanding />
                  },
                  {
                    path: "std",
                    element: <ProjsSAPStandardPages />
                  },
                  {
                    path: "txt",
                    element: <ProjsSAPText />
                  },
                ],
              },
              {
                path: "manage-vars",
                element: <ManageVariables />,
              },
              {
                path: "save",
                element: <ProjsSave />,
              },
              {
                path: "export",
                element: <ProjsExport />,
              }
            ]
          }, 
          {
            path: "profile",
            element: <Profile />,
          },
          {
            path: "docs/edit/:id",
            element: <DocsOutlet />,
            children: [
              {
                path: "var",
                element: <DataFormOutlet />,
                children: [
                  {
                    path: "",
                    element: <DataFormLanding />,
                  },
                  { 
                    path: "pop",
                    element: <DataForm page="Population" />,
                  },
                  {
                    path: "study",
                    element: <DataForm page="Study Information" />
                  }
                ]
              },
              {
                path:"syn",
                element: <DocsSynopsis />,
              },
              {
                path: "txt",
                element: <DocsText />,
              },
              {
                path: "std",
                element: <DocsStandardPages />,
              },
              {
                path: "manage-vars",
                element: <ManageVariables />,
              },
              {
                path: "save",
                element: <DocsSave />,
              },
              {
                path: "export",
                element: <DocsExport />,
              }
            ]
          },
          {
            path: "temps/edit/:id",
            element: <TempsOutlet />,
            children: [
              {
                path: "",
                element: <TempsOutline />, // Adjust so this opens to Assemble if it is new & text otherwise
              },
              {
                path: "outline",
                element: <TempsOutline />,
              },
              {
                path: "std",
                element: <TempsStandardPages />,
              },
              {
                path: "txt",
                element: <TempsText />,
              },
              {
                path: "syn",
                element: <TempsSynopsis />,
              },
              {
                path: "manage-vars",
                element: <ManageVariables />,
              },
              {
                path: "save",
                element: <TempsSave />,
              }
            ],
          },
          {
            path: "projs/new",
            element: <NewProjOutlet />,
            children: [
              {
                path: "",
                element: <NewProjInfo />,
              },
              {
                path: "info",
                element: <NewProjInfo />
              },
              {
                path: "users",
                element: <NewProjUsers />,
              },
              {
                path: "temps",
                element: <NewProjTemplates />,
                children: [
                  {
                    path: "",
                    element: <>Select a Document type to see a list of available protocols.</>,
                  },
                  {
                    path: "proto",
                    element: <SelectorList page="proj-creator" type="protocol" />,
                  },
                  {
                    path: "sap",
                    element: <SelectorList page="proj-creator" type="sap" />,
                  }
                ]
              },
              {
                path: "clone",
                element: <NewProjClone />,
                children: [
                  {
                    path: "",
                    element: <SelectorList page="proj-clone" type="protocol" />,
                  },
                ],
              }
            ],
          },
          {
            path: "docs/new",
            element: <NewDocOutlet />,
            children: [
              {
                path: "",
                element: <NewDocInfo />,
              },
              {
                path: "info",
                element: <NewDocInfo />
              },
              {
                path: "users",
                element: <NewDocUsers />,
              },
              {
                path: "temps",
                element: <NewDocTemplates />,
                children: [
                  {
                    path: "",
                    element: <>Select a Document type to see a list of available protocols.</>,
                  },
                  {
                    path: "proto",
                    element: <SelectorList page="doc-creator" type="protocol" />,
                  },
                  {
                    path: "sap",
                    element: <SelectorList page="doc-creator" type="sap" />,
                  }
                ]
              },
              {
                path: "clone",
                element: <NewDocClone />,
                children: [
                  {
                    path: "",
                    element: <>Select a Document type to see a list of available documents.</>
                  },
                  {
                    path: "proto",
                    element: <SelectorList page="doc-clone" type="protocol" />,
                  },
                  {
                    path: "sap",
                    element: <SelectorList page="doc-clone" type="sap" />,
                  }
                ],
              }
            ],
          },
          {
            path: "temps/new",
            element: <NewTempOutlet />,
            children: [
              {
                path: "",
                element: <NewTempInfo />,
              },
              {
                path: "info",
                element: <NewTempInfo />
              },
              {
                path: "users",
                element: <NewTempUsers />
              },
              {
                path: "parent",
                element: <NewTempTemplates />,
                children: [
                  {
                    path: "",
                    element: <>Select a Document type to see a list of available protocols.</>,
                  },
                  {
                    path: "proto",
                    element: <SelectorList page="temp-creator" type="protocol" />,
                  },
                  {
                    path: "sap",
                    element: <SelectorList page="temp-creator" type="sap" />,
                  }
                ]
              },
            ],
          },
        ]
      },
    ]
  },
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);