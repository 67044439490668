export function Trash() {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" 
      fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 
        2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 
        0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 
        0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0zm1.958 
        1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 
        1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 
        .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 
        .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
    </svg>
  );
}
export function Up() {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" 
      width="16" height="16" fill="currentColor" className="bi bi-arrow-up-short" 
      viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 
      2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 
      .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5"/>
    </svg>
  );
}

export function Down() {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" 
      fill="currentColor" className="bi bi-arrow-down-short" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 
      0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 
      0 0 1 8 4"/>
    </svg>
  );
}

export function Left() {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" 
      width="16" height="16" fill="currentColor" 
      className="bi bi-arrow-left-short" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 
      2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 
      .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"/>
    </svg>
  );
}

export function Right() {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" 
      width="16" height="16" fill="currentColor" 
      className="bi bi-arrow-right-short" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 
      5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 
      1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
    </svg>  
  );
}
function Plus() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" 
        fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 
          0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
    </svg>
  );
}

export function Delete() {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" 
        viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_328_2327)">
        <path d="M7.99974 4.75313C7.57347 4.75313 7.15138 4.83709 6.75755 
          5.00022C6.36373 5.16335 6.00589 5.40245 5.70448 5.70387C5.40306 6.00528 
          5.16396 6.36312 5.00083 6.75694C4.8377 7.15077 4.75374 7.57286 4.75374 
          7.99913C4.75374 8.4254 4.8377 8.8475 5.00083 9.24132C5.16396 9.63515 
          5.40306 9.99298 5.70448 10.2944C6.00589 10.5958 6.36373 10.8349 6.75755 
          10.998C7.15138 11.1612 7.57347 11.2451 7.99974 11.2451C8.86064 11.2451 
          9.68627 10.9031 10.295 10.2944C10.9038 9.68566 11.2457 8.86003 11.2457 
          7.99913C11.2457 7.13824 10.9038 6.31261 10.295 5.70387C9.68627 5.09512 
          8.86064 4.75313 7.99974 4.75313ZM5.75374 7.99913C5.75374 7.40346 5.99038 
          6.83218 6.41158 6.41097C6.83279 5.98977 7.40407 5.75313 7.99974 5.75313C8.59542 
          5.75313 9.1667 5.98977 9.58791 6.41097C10.0091 6.83218 10.2457 7.40346 10.2457 
          7.99913C10.2457 8.59481 10.0091 9.16609 9.58791 9.5873C9.1667 10.0085 8.59542 
          10.2451 7.99974 10.2451C7.40407 10.2451 6.83279 10.0085 6.41158 9.5873C5.99038 
          9.16609 5.75374 8.59481 5.75374 7.99913Z" fill="currentColor"/>
        <path d="M9.79574 1.34213C9.26874 -0.447866 6.73074 -0.447866 6.20374 
          1.34213L6.10974 1.66113C6.07294 1.78609 6.00864 1.90122 5.92156 1.99809C5.83448 
          2.09497 5.72682 2.17112 5.60649 2.22099C5.48615 2.27085 5.35618 2.29315 5.2261 
          2.28625C5.09602 2.27936 4.96914 2.24344 4.85474 2.18113L4.56274 2.02113C2.92274 
          1.12913 1.12974 2.92313 2.02274 4.56213L2.18174 4.85413C2.24405 4.96853 2.27997 
          5.09541 2.28686 5.22549C2.29376 5.35557 2.27146 5.48554 2.2216 5.60588C2.17173 
          5.72621 2.09558 5.83387 1.9987 5.92095C1.90183 6.00803 1.7867 6.07233 1.66174 
          6.10913L1.34274 6.20313C-0.447256 6.73013 -0.447256 9.26813 1.34274 
          9.79513L1.66174 9.88913C1.7867 9.92594 1.90183 9.99024 1.9987 10.0773C2.09558 
          10.1644 2.17173 10.2721 2.2216 10.3924C2.27146 10.5127 2.29376 10.6427 2.28686 
          10.7728C2.27997 10.9029 2.24405 11.0297 2.18174 11.1441L2.02174 11.4361C1.12974
          13.0761 2.92274 14.8701 4.56274 13.9761L4.85474 13.8171C4.96914 13.7548 5.09602 
          13.7189 5.2261 13.712C5.35618 13.7051 5.48615 13.7274 5.60649 13.7773C5.72682 
          13.8271 5.83448 13.9033 5.92156 14.0002C6.00864 14.097 6.07294 14.2122 6.10974 
          14.3371L6.20374 14.6561C6.73074 16.4461 9.26874 16.4461 9.79574 14.6561L9.88974 
          14.3371C9.92655 14.2122 9.99085 14.097 10.0779 14.0002C10.165 13.9033 10.2727 
          13.8271 10.393 13.7773C10.5133 13.7274 10.6433 13.7051 10.7734 13.712C10.9035 
          13.7189 11.0304 13.7548 11.1447 13.8171L11.4367 13.9771C13.0767 14.8701 14.8707 
          13.0751 13.9767 11.4361L13.8177 11.1441C13.7554 11.0297 13.7195 10.9029 13.7126 
          10.7728C13.7057 10.6427 13.728 10.5127 13.7779 10.3924C13.8278 10.2721 13.9039 
          10.1644 14.0008 10.0773C14.0977 9.99024 14.2128 9.92594 14.3377 9.88913L14.6567 
          9.79513C16.4467 9.26813 16.4467 6.73013 14.6567 6.20313L14.3377 6.10913C14.2128 
          6.07233 14.0977 6.00803 14.0008 5.92095C13.9039 5.83387 13.8278 5.72621 13.7779 
          5.60588C13.728 5.48554 13.7057 5.35557 13.7126 5.22549C13.7195 5.09541 13.7554 
          4.96853 13.8177 4.85413L13.9777 4.56213C14.8707 2.92213 13.0757 1.12913 11.4367
          2.02213L11.1447 2.18113C11.0304 2.24344 10.9035 2.27936 10.7734 2.28625C10.6433 
          2.29315 10.5133 2.27085 10.393 2.22099C10.2727 2.17112 10.165 2.09497 10.0779 
          1.99809C9.99085 1.90122 9.92655 1.78609 9.88974 1.66113L9.79574 1.34213ZM7.16274 
          1.62513C7.40874 0.790134 8.59074 0.790134 8.83674 1.62513L8.93074 1.94413C9.00976 
          2.21222 9.14778 2.45922 9.33467 2.66703C9.52156 2.87484 9.75259 3.0382 10.0108 
          3.14511C10.2691 3.25203 10.5479 3.2998 10.827 3.28492C11.1061 3.27005 11.3783 
          3.1929 11.6237 3.05913L11.9147 2.89913C12.6787 2.48413 13.5147 3.31913 13.0987 
          4.08413L12.9397 4.37613C12.8062 4.62156 12.7292 4.89376 12.7145 5.17279C12.6998 5.45182 12.7477 5.7306 12.8547 5.98872C12.9617 6.24683 13.1251 6.47774 13.3329 6.66451C13.5407 6.85128 13.7877 6.98919 14.0557 7.06813L14.3737 7.16213C15.2087 7.40813 15.2087 8.59013 14.3737 8.83613L14.0547 8.93013C13.7867 9.00915 13.5397 9.14717 13.3318 9.33406C13.124 9.52095 12.9607 9.75198 12.8538 10.0102C12.7468 10.2684 12.6991 10.5473 12.714 10.8264C12.7288 11.1055 12.806 11.3777 12.9397 11.6231L13.0997 11.9141C13.5147 12.6781 12.6797 13.5141 11.9147 13.0981L11.6237 12.9391C11.3783 12.8054 11.106 12.7283 10.8268 12.7135C10.5477 12.6986 10.2688 12.7465 10.0105 12.8535C9.75228 12.9605 9.52127 13.124 9.33442 13.3319C9.14758 13.5399 9.00965 13.787 8.93074 14.0551L8.83674 14.3731C8.59074 15.2081 7.40874 15.2081 7.16274 14.3731L7.06874 14.0541C6.98969 13.7862 6.8517 13.5393 6.66488 13.3316C6.47805 13.1239 6.24714 12.9606 5.98903 12.8537C5.73093 12.7468 5.45219 12.699 5.17321 12.7138C4.89424 12.7286 4.62211 12.8056 4.37674 12.9391L4.08474 13.0991C3.32074 13.5141 2.48474 12.6791 2.90074 11.9141L3.05974 11.6231C3.1937 11.3777 3.271 11.1054 3.28597 10.8262C3.30095 10.547 3.25322 10.2679 3.14629 10.0096C3.03936 9.75122 2.87594 9.52008 2.66802 9.33312C2.46011 9.14616 2.21297 9.00812 1.94474 8.92913L1.62574 8.83513C0.790744 8.58913 0.790744 7.40713 1.62574 7.16113L1.94474 7.06713C2.21255 6.98804 2.45928 6.85007 2.66689 6.66332C2.87449 6.47657 3.03771 6.24576 3.1446 5.98779C3.25149 5.72982 3.29934 5.45121 3.28466 5.17235C3.26998 4.8935 3.19313 4.62146 3.05974 4.37613L2.89974 4.08413C2.48474 3.32013 3.31974 2.48413 4.08474 2.90013L4.37674 3.05913C4.62211 
          3.19271 4.89424 3.26971 5.17321 3.28449C5.45219 3.29927 5.73093 3.25145 5.98903 
          3.14455C6.24714 3.03765 6.47805 2.87436 6.66488 2.66665C6.8517 2.45895 6.98969 
          2.21208 7.06874 1.94413L7.16274 1.62513Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_328_2327">
          <rect width="16" height="16" fill="var(--primary-bgrd-color)"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export function Pencil() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" 
        viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_328_2311)">
        <path d="M2 11.5V14H4.5L11.8733 6.62668L9.37333 4.12668L2 11.5ZM14.2733 
            4.22668L11.7733 1.72668L10.0867 3.42002L12.5867 5.92002L14.2733 
            4.22668V4.22668Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_328_2311">
          <rect width="16" height="16" fill="var(--primary-bgrd-color)"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export function Profile(props) {  
  
  const scale={
    transform: 'scale(2.3)',
  };
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" 
        className="bi bi-circle bi-person-fill" viewBox="0 0 16 16" style={scale}>
      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" fill={props.color1 || 'currentColor'}/>
      <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 
          0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
          fill={props.color1 || 'currentColor'} />
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
          fill={props.color2 || 'currentColor'} />
    </svg>
  );
}

export default Plus;