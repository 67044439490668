import { 
  Link, 
  Outlet } from "react-router-dom";
import { Row } from "react-bootstrap";

export default function NewProjTemplates(){
  return(
    <div>
      New Project Templates landing page coming soon!<br/>
      At the top you will see a list of templates to be used in the project.<br/>
      And then when you select what template to browse you will see the template list below. <br/>
      <br/>
      <Link to="proto">Protocols</Link>&nbsp; &nbsp; &nbsp; &nbsp;<Link to="sap">SAPs</Link>
      <Row className="p-5">
        <Outlet />
      </Row>
    </div>
  );
}