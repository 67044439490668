import { Outlet } from "react-router-dom";
import {
    Row,
    Col } from "react-bootstrap";

import NavSide from "./components/NavSide";

export default function ProjsOutlet(){
  console.log('here');
  /* Define Items in the Sidebar */
  const sideNav = [
    {
      title: "Data Form",
      page: "var"
    },
      {
        title: "Study Info",
        page: "var/study",
        sub: "level2",
      },
      {
        title: "Population",
        page: "var/pop",
        sub: "level2",
      },
      {
        title: "Protocol",
        page: "proto",
      },
        {
          title: "Main Text",
          page: "proto/txt",
          sub: "level2",
        },
        {
          title: "Synopsis",
          page: "proto/syn",
          sub: "level2",
        },
        {
          title: "Standard Pages",
          page: "proto/std",
          sub: "level2",
        },
    {
      title: "SAP",
      page: "sap",
    },
      {
        title: "Main Text",
        page: "sap/txt",
        sub: "level2",
      },
      {
        title: "Standard Pages",
        page: "sap/std",
        sub: "level2",
      },
    {
      title: "Manage Variables",
      page: "manage-vars"
    },
    {
      title: "Export",
      page: "export"
    }
  ]

  return(
    <>
      <Row className="page-head">
        <h1>
          #ProjectTitle
        </h1>
      </Row>
      <Row>
        <Col sm="2" className="mx-0 px-0">
          <NavSide elements={sideNav} type="projs"/>
        </Col>
        <Col className="px-5">
          <Outlet />
        </Col>
      </Row>
    </>
  );
}