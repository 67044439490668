import { Editor } from '@tinymce/tinymce-react';
import { useRef } from "react";

function TinyEditor(props){
  const editorRef = useRef(null);  

  return(
    <div className="tinybox">
      <Editor
        tinymceScriptSrc={'/tinymce/tinymce.min.js'}
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={props.text || ""}
        className="tinyedit"
        init={{
          branding: false,
          menubar: false,
          plugins: [
            'advlist', 'lists', 'image', 'charmap',
            'anchor', 'searchreplace', 'visualblocks', 
            'insertdatetime', 'media', 'table',' asclepia-group-variables'
          ],
          toolbar: 'undo redo | h1 h2 h3 | asclepia-group-variables |' +
            'bold italic | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | table | image',
          /*variables: variableData.activeVariables, */
          description : "",
          /*process_new_variable : save_variable,
          images_upload_handler: s3_image_upload_handler, */
          file_picker_types: 'image',
          image_advtab: true,
          image_uploadtab: true,
          images_file_types: 'jpeg,jpg,png,gif',
          content_css: '/tiny.css', 
        }}    
      /> 
    </div>
  );
}

export default TinyEditor;