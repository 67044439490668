import { Button } from "react-bootstrap";

import Plus from "./Icons";
import {Pencil, Delete, Up, Down, Left, Right } from "./Icons";

/*Available Props include: 
variants: create, 
*/
function AscButton(props){

  return (
    <Button onClick={props.onClick} variant={props?.variant} disabled={props?.startoff}>
      {props.variant==="create" ? <><Plus />Create New </>
          : props.variant==="select" ? <>Select</> 
          : props.variant==="edit" ? <><Pencil />Edit</> 
          : props.variant==="delete" ? <><Delete />Delete</>
          : props.variant==="tinyedit-head" ? <Pencil /> 
          : props.variant==="tinyedit-save" ? <>Save</>
          : props.variant==="tinyedit-cancel" ? <>Cancel</> 
          : props.variant==="arrow-left" ? <Left />
          : props.variant==="arrow-up" ? <Up />
          : props.variant==="arrow-right" ? <Right />
          : props.variant==="arrow-down" ? <Down />
          : ""}{props.children}
    </Button>
  )

}

export default AscButton;