import { Nav } from 'react-bootstrap';
import { 
  Link, 
  useNavigate } from 'react-router-dom';

import AscButton from './AscButton';

function ListItem(props) {
  const styleType= "side nav-link "+props?.sub;

  return(
    <Nav.Item>
      <Link to={props.page} className={styleType}>
        {props.title} 
      </Link>
    </Nav.Item>
  );
}

export default function NavSide(props) {

  const navLinks = Object.keys(props.elements);
  const navigate = useNavigate();
  
  function onClickFinalBtn() {
    if( props?.finalBtn==="Create"){
      const urlCreate="/app/"+props?.type+"/edit/:id";
      navigate(urlCreate);
    }
    else{
      navigate("save");
    }
  }

  if (navLinks.length) {
    return(
      <Nav className="pt-4 side d-block">      
        {
          navLinks.map((item) => {
            return(
              <ListItem 
                  title={props.elements[item].title} 
                  page={props.elements[item].page}
                  key={"nav-side-"+item}
                  sub={props.elements[item].sub} 
              />
            );
          })
        }
        <br/>
        <AscButton onClick={onClickFinalBtn} variant="primary">{props?.finalBtn || 'Save'}</AscButton>
      </Nav>
    );
  }
  else{ return("Add links to start");} 
}