import { ButtonGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import AscButton from "./AscButton";

function AscButtonGroup(props){
  const id=props.item?.id || "";

  const navigate=useNavigate();

  function onClickEdit() {
    (props.type==="list" & id !== "") ? 
      navigate("edit/"+id)
    : console.log("No onClickEdit() function exists for "+props.type);
  }

  function onClickSelect() {
    (props.type==="list") ? 
      navigate(id)
    : console.log("No onClickSelect() function exists for "+props.type);
  }

  return(
    <ButtonGroup className={props?.variant}>
      {props?.variant==="edit-delete" 
        ? 
          <>
            <AscButton onClick={onClickEdit} variant='edit' />
            <AscButton variant='delete' />
          </>
        : props?.variant==='select'
          ?
          <>
            <AscButton variant='select' onClick={onClickSelect} />
          </>
        : props?.variant==='arrange' 
        ?
          <>
            <span className="helper">Arrange Sections &nbsp; &nbsp;</span>
            <AscButton variant='arrow-up' />
            <AscButton variant='arrow-down' />
            <AscButton variant='arrow-left' />
            <AscButton variant='arrow-right' />
          </>
        : props?.variant==='cancel-save'
          ?
          <>
            <AscButton variant='tinyedit-cancel' />
            <AscButton variant='tinyedit-save' startoff="{true}" />
          </>
        
        : ""
      }
    </ButtonGroup>
  );
}

export default AscButtonGroup;