import { 
  Container, 
  Row, 
  Col, 
  Button } from 'react-bootstrap';

import AscButton from "./components/AscButton";
import AscButtonGroup from "./components/AscButtonGroup";
import NavSide from "./components/NavSide";
import TinyEditor from "./components/TinyEditor";
import TinyPreview from "./components/TinyPreview";
import ElementList from "./components/ElementList";
import EditSection from "./components/EditSection";
import Plus from "./components/Icons";
import { 
    Pencil,
    Delete, 
    Left, 
    Right, 
    Up, 
    Down, 
    Profile, 
    Trash } from "./components/Icons";
import LogoIpsum from "./components/LogoIpsum";

export default function ComponentTest() {
  
  /* Define Items in the Sidebar */
  const sidenav1 = [
    {
      title: "Sidebar",
      page: "e1",
      click: "action",
    },
    {
      title: "Data Manager",
      page: "e2",
      click: "action2",
    },
    {
      title: "Protocol",
      page: "e3",
      click: "action3",
    },
    {
      title: "Synopsis",
      page: "e4",
      click: "action4",
    }
  ]


  // Sample elements list
  const sampleList = [
    {
      title: "Sample Protocol Name",
      description: "based on elemental, version 3.4"
    },
    {
      title: "Another Name of a Protocol Hybrid",
      description: "shorter here, draft"
    },
    {
      title: "Sample Protocol Name",
      description: "based on elemental, version 3.4"
    },
    {
      title: "Another Name of a Protocol Hybrid",
      description: "shorter here, draft"
    },
    {
      title: "Sample Protocol Name",
      description: "based on elemental, version 3.4"
    },
    {
      title: "Another Name of a Protocol Hybrid",
      description: "shorter here, draft"
    }
  ]
  

  /* Some lorem ipsum text */ 
  const lorem = "I came tiptoe into our own room, and found Mina asleep, breathing so softly that I had to put my ear down to hear it. She looks paler than usual. I hope the meeting to-night has not upset her. I am truly thankful that she is to be left out of our future work, and even of our deliberations. It is too great a strain for a woman to bear. I did not think so at first, but I know better now. Therefore I am glad that it is settled. There may be things which would frighten her to hear; and yet to conceal them from her might be worse than to tell her if once she suspected that there was any concealment. Henceforth our work is to be a sealed book to her, till at least such time as we can tell her that all is finished, and the earth free from a monster of the nether world. I daresay it will be difficult to begin to keep silence after such confidence as ours; but I must be resolute, and to-morrow I shall keep dark over to-night's doings, and shall refuse to speak of anything that has happened. ";

  return (
      <Container fluid className="p-0 m-0">
        <Row className="page-head">
          <Col md="12">
            <Row>
              <Col md="7">
                <h1>
                  Text Samples (h1)
                </h1>
                <p className="instrux">
                  This is an example of page instructions that tell you how to use things.
                </p>
              </Col>
              <Col>
                <Row>
                  <Col md="6" className="offset-md-1">
                    <div className="component-mu">
                      h1<br/>
                      inherit color<br/>
                    </div>
                  </Col>
                  <Col>
                    <div className="component-mu">
                      p / "instrux"<br/>
                      inherit color<br/>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md="12">
                <Row>
                  <Col md="7">
                    <h2>Sample h2</h2><br/>
                  </Col>
                  <Col className="offset-md-1">
                    <div className="component-mu">
                      h2<br/>
                      inherit font color<br/>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="7">
                    <h3>Sample h3</h3>
                  </Col>
                  <Col className="offset-md-1">
                    <div className="component-mu">
                      h3<br/>
                      inherit color <br/>
                      Borderline on bottom (solid 70% tint of dark (#454E62))
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="7">
                    <p>{lorem}</p>
                  </Col>
                  <Col className="offset-md-1">
                    <div className="component-mu">
                      p<br/>
                      inherit color<br/>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="page-head mt-5 pt-5">
          <h1>
            Icons
          </h1>
        </Row>
        <Row>
          <Col md="10">
            <Row>
              <Col md="8" className="ps-5">
                <Profile /> 
              </Col>
              <Col className="offset-md-1">
                <div className="component-mu">
                  Profile icon<br/>
                  inherits color
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="8" className="ps-5">
                <LogoIpsum />
              </Col>
              <Col className="offset-md-1">
                <div className="component-mu">
                  LogoIpsum icon<br/>
                  brand colors
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="8" className="ps-5">
                <Plus /> &nbsp; &nbsp; &nbsp; &nbsp;
                <Pencil /> &nbsp; &nbsp; &nbsp; &nbsp;
                <Delete /> &nbsp; &nbsp; &nbsp; &nbsp;
                <Trash /> &nbsp; &nbsp; &nbsp; &nbsp;
                <Up /> &nbsp; &nbsp; &nbsp; &nbsp;
                <Down /> &nbsp; &nbsp; &nbsp; &nbsp;
                <Left /> &nbsp; &nbsp; &nbsp; &nbsp;
                <Right /> &nbsp; &nbsp; &nbsp; &nbsp;
              </Col>
              <Col className="offset-md-1">
                <div className="component-mu">
                  other icons<br/>
                  Plus  Pencil  Delete  (Trash) Up  Down  Left  Right
                  inherit color
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="page-head mt-5 pt-5">
          <h1>
            Buttons
          </h1>
        </Row>
        <Row>
          <Col md="10" className="px-5 d-inline">
            <Row>
              <Col md="8"> 
                <AscButton variant='create'>Children</AscButton>
              </Col>
              <Col className="offset-md-1">
                <div className="component-mu">
                  "create" button<br/>
                  Primary font color<br/>
                  Plus icon
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <AscButton variant='tinyedit-head'>Guidance Text</AscButton>
              </Col>
              <Col className="offset-md-1">
                <div className="component-mu">
                  "tinyedit-head" button<br/>
                  Primary font color<br/>
                  Pencil icon
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <AscButtonGroup variant='arrange' /> 
              </Col>
              <Col className="offset-md-1">
                <div className="component-mu">
                  "arrange" button group<br/>
                  inherit font color<br/>
                  transparent background<br/>
                  Secondary font size (0.875rem)
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <AscButtonGroup variant="edit-delete" />
              </Col>
              <Col className="offset-md-1"> 
                <div className="component-mu">
                  "edit-delete" button group<br/>
                  Secondary font color<br/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <AscButtonGroup variant='cancel-save' />
              </Col>
              <Col className="offset-md-1">
                <div className="component-mu">
                  "cancel-save" button group<br/>
                  Secondary color<br/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Button variant='primary'>Primary Button</Button>
              </Col>
              <Col className="offset-md-1">
                <div className="component-mu">
                  "primary" button<br/>
                  Primary background<br/>
                  inherit font size<br/>
                  100% width
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Button variant='secondary'>Secondary Button</Button>
              </Col>
              <Col className="offset-md-1">
                <div className="component-mu">
                  "secondary" button<br/>
                  Secondary font color<br/>
                  transparent background<br/>
                  inherit font size<br/>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="page-head mt-5 pt-5">
          <h1>
            Text Editor (Tiny MCE)
          </h1>
        </Row>
        <Row>
          <Col md="10">
            <Row>
              <Col md="8">
                <TinyEditor text={lorem} />
              </Col>
              <Col className="offset-md-1">
                <div className="component-mu">
                  Tiny Text Editor<br/>(node package tinyMCE)<br/>
                  Individual elements are easily styled, but not yet adjusted from the default package
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <h3>Guidance Text</h3>
                <TinyPreview text={lorem} variant="guidance" />
                <h3>Main Text</h3>
                <TinyPreview text={lorem} variant="mainpreview" />
              </Col>
              <Col className="offset-md-1">
                <div className="component-mu">
                  TextPreview<br/>
                  Text that appears in place of Tiny Edit when not being edited<br/>
                  Document text appears as Normal<br/>
                  Guidance text appears as Italic
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5 pt-5 page-head">
          <h1>
            Side Navigation
          </h1>
        </Row>
        <Row className="sidebar-preview">
          <Col sm="2" className="mx-0 px-0">
            <NavSide elements={sidenav1} />
          </Col>
          <Col className="px-5">
            {lorem}
          </Col>
        </Row>
        <Row className="mt-5 pt-5 page-head">
          <h1>
            Document Lists
          </h1>
        </Row>
        <Row>
          <ElementList variant="draft" page="template" type="protocol" listItems={sampleList}/>
        </Row>
        <Row>
          <ElementList variant="live" page="doc-creator" type="protocol" listItems={sampleList} />
        </Row>
        <Row className="mt-5 pt-5 page-head">
          <h1>
            Text Editor Block
          </h1>
        </Row>
        <Row>
          <EditSection title="1. Section Title" textGuidance="Here is some guidance text."
              textMain={lorem} editorSection="1. Section Title" editorPart="guidance" />
        </Row>
        <Row className="mt-5 pt-5 page-head">
          <h1 className="with-instrux">
            Variable Editor Block
          </h1>
          <p className="instrux">
            We do not yet have redesigns for this, but I will pull elements from Data Manager.
          </p>
        </Row>
      </Container>
  ); 
}